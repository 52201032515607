.faq {
    width: 95vw;
    height: 95%;
    margin: 0 0 5px 2%;
    /* margin-bottom: 5px;
    margin-left: 2%; */
    border: 1px solid black;
    border-top-left-radius: 25px;
    border-bottom-right-radius: 25px;
    text-align: left;
  }
  
  .faq p{
    width: 90vw;
    margin-left: 2%;
  }
  
  .faq h2 {
    padding-left: 2%;
    background: linear-gradient(to left, rgb(252, 251, 251), 50%, rgba(0, 0, 0, 0.9));
    color: white;
  }
  
  .faq h2:hover{
    background: linear-gradient(to right, rgb(252, 251, 251), 50%, rgba(0, 0, 0, 0.9));
    color: black;
  }
  
  #first_faq_h2 {
    border-top-left-radius: 25px;
    width: 100%;
    /* border-left: 1px solid black; */
  }
  
  @media screen and (max-width:1170px) {
    .faq h2 {
      font-size: large;
    }
  }