.App {
  text-align: center;
  max-width: 100vw;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: black;
}

a:visited, a:hover, a:active {
  color: inherit;
}

#main_h1 {
  position:absolute;
  margin: 0 0 0 15px;
  font-family: 'Amatic SC', cursive;
  font-size: 300%;
}

#dog_banner {
  width: 100%;
  margin-top: 15px;
  z-index: 0;
  width: 95%;
}

.contact_p:hover{
  font-size: x-large;
}

@media screen and (max-width:1170px) {
  #main_h1{
    font-size: 250%;
  }
}

@media screen and (max-width:900px) {
  #main_h1{
    font-size: 200%;
  }
}

@media screen and (max-width:750px) {
  #main_h1{
    font-size:x-large;
  }
}