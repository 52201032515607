.header {
    position: absolute;
    height: 75px;
    /* width: 100vw; */
    top: 0;
    left: 63%;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
    color: black;
    /* background-color: rgba(255, 255, 255, 0.4); */
}

.header a {
    color: black;
}

.header .navbar {
    display: flex;
    align-items: center;
    max-width: 100vw;
    margin: auto;
    height: 100%;
    padding: 0 1rem;
    justify-content: space-between;
}

.header .nav-menu {
    display: flex;
    padding-left: 0;
}

.header .nav-item {
    padding: 1rem;
    font-size: small;
    /* font-weight: 500; */
    /* color:#014f86; */
}

.header .nav-item a:hover {
    border-bottom: 2px solid;
}

.hamburger {
    display: none;
}

@media screen and (max-width:1170px) {
    .header {
        max-width: 100%;
        top: -1%;
        left: 90vw;
    }

    .header .navbar {
        max-width: 100%;
    }
    
    .hamburger {
        display: block;
        cursor: pointer;
    }

    .nav-menu {
        position: fixed;
        right: -100%;
        top: 5rem;
        flex-direction: column;
        background: rgba(164, 162, 162, 0.85);
        border-radius: 5px;
        width: 120px;
        height: 90vh;
        z-index: 999;
        text-align: center;
        transition: .3s;

    }

    .nav-menu.active {
        right: 0;
    }

    .nav-item {
        display: flex;
        /* justify-content: center; */
        margin: 0 50px 0 0;
        align-items: center;
        padding-left: 0;
    }

    .nav-item:hover {
        color:darkgray;
    }
}
