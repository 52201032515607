.testimonial_card {
    border: 1px solid black;
    margin: 15px;
    border-top-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    text-align: justify;
}

#testimonial_card_p {
    padding: 15px;
    /* position: relative; */
}

#testimonial_card_name {
    padding: 10px;
    background: linear-gradient(to left, rgb(252, 251, 251), 50%, rgba(0, 0, 0, 0.9));
    color: white;
    width: 94.5;
    border-bottom-right-radius: 2rem;
    margin: 30px 0 0 0;
}

#testimonial_card_name:hover{
    background: linear-gradient(to right, rgb(252, 251, 251), 50%, rgba(0, 0, 0, 0.9));
    color: black;
}

#left_quote{
    float: left;
    margin: 10px 0 0 10px;
    font-size: 350%;
    opacity: 0.3;
}

#right_quote{
    float: right;
    margin: 0 10px 10px 0;
    font-size: 350%;
    opacity: 0.3;
}

#testimonial_text{
    width: 70%;
    height: fit-content;
}

#testimonial_main{
    display: flex;
    justify-content: space-around;
}

#testimonial_dog img{
    border-top-left-radius: 2rem;
    height: 25vh;
    width: auto;
    margin-top: 10px;
}